import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        signedIn: false,
        selectedSucursal: null,
    },
	reducers: {
        onLoginSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
            state.selectedSucursal = action.payload.selectedSucursal;
        },
        onLogoutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.selectedSucursal = null
        },
        setToken: (state, action) =>  {
            state.token = action.payload
        },
        setSucursal: (state, action) => {
            state.selectedSucursal = action.payload
        }
	},
})

export const { onLoginSuccess, onLogoutSuccess, setToken, setSucursal } = sessionSlice.actions

export default sessionSlice.reducer
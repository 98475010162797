import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { THEME_ENUM } from 'constants/theme.constant'
import { setMode } from 'store/theme/themeSlice'

function useDarkMode() {
    const mode = useSelector((state) => state.theme.mode)
    const { MODE_DARK, MODE_LIGHT } = THEME_ENUM

    const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
    //const isEnabledDarkDefault = mode === MODE_DARK 
    const isEnabledDarkDefault = isBrowserDefaultDark(); 

    const dispatch = useDispatch()
    const onModeChange = mode => dispatch(setMode(mode))

    if(isEnabledDarkDefault && (mode === MODE_LIGHT)){
        onModeChange(MODE_DARK)
    }


    useEffect(() => {
        if (window === undefined) {
            return
        }
        const root = window.document.documentElement
        root.classList.remove(isEnabledDarkDefault ? MODE_LIGHT : MODE_DARK)
        root.classList.add(isEnabledDarkDefault ? MODE_DARK : MODE_LIGHT)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnabledDarkDefault])
            
    return [isEnabledDarkDefault, onModeChange]
}

export default useDarkMode
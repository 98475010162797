import ApiService from '../ApiService'
import { API_URL_PATH, API_AUTH_LOGIN_CONFIG, API_URL_PATH_VERSION } from 'constants/api.constant'
import { isEmpty, isUndefined } from 'lodash'

export async function apiLogin (data) {

    //Formato segun los parametros del servicio
    //GET {usuario, clave}
    let params = {}
    if(!isUndefined(data.username)) params.usuario = data.username;
    if(!isUndefined(data.password)) params.clave = data.password;
    if(!isEmpty(API_AUTH_LOGIN_CONFIG)) 
        params = {
            ...params,
            ...API_AUTH_LOGIN_CONFIG
        };
    
    return ApiService.fetchData({
        url:  API_URL_PATH + '/User/LoginUserSinergie?usuario=' + params.usuario + '&clave=' + params.clave, 
        method: 'post', 
        params
    })
    // return ApiService.fetchData({
    //     url:  API_URL_PATH + '/User/LoginUserSinergie', 
    //     method: 'post',
    //     params
    // })
}

export async function apiGetUserInfo (data) {
    return {
        success: true,
        message: "Aclaración del mensaje",
        data: {
            user: { 
                avatar: '', 
                userName: 'USUARIO', 
                authority: ['administrator'], 
                email: 'USUARIO@USUARIO.com'
            }
        }
    }
    // return ApiService.fetchData({
    //     url: API_URL_PATH_VERSION + '/user/info',
    //     method: 'get',
    //     data
    // })
}

export async function apiLogout (data) {
    return {
        data: {
            message: 'Exito',
            url: API_URL_PATH_VERSION
        }
    }
    // return ApiService.fetchData({
    //     url: API_URL_PATH_VERSION + '/User/DesloginUserSinergie',
    //     method: 'post',
    //     data
    // })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: API_URL_PATH + '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: API_URL_PATH + '/reset-password',
        method: 'post',
        data
    })
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: API_URL_PATH + '/sign-up',
        method: 'post',
        data
    })
}

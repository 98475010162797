import {
  isArray,
  isEmpty,
  isNull,
  isObject,
  isString,
  isUndefined,
  trim
} from "lodash";

export function buildFormData(formData, data, exception = [], parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    (typeof parentKey === "undefined" ||
      (exception.length > 0 && !exception.includes(parentKey)))
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        exception,
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    if (
      typeof parentKey === "undefined" ||
      (exception.length > 0 && !exception.includes(parentKey))
    ) {
      const value = data === null ? "" : data;

      formData.append(parentKey, value);
    }
  }
}
export function jsonToFormData(data, exception = []) {
  const formData = new FormData();

  buildFormData(formData, data, exception);

  return formData;
}
export function isNotNull(variable) {
  if (!isUndefined(variable) && !isNull(variable)) return true;
  else return false;
}
export function isNotEmpty(variable) {
  if (isNotNull(variable) && isObject(variable) && !isEmpty(variable))
    return true;
  else return false;
}
export function isArrayCount(variable, countMin = 0, equal = false) {
  if (
    (!isUndefined(variable) &&
      isArray(variable) &&
      !equal &&
      variable.length > countMin) ||
    (equal && variable.length >= countMin)
  )
    return true;
  else return false;
}
export function isStringCount(variable, countMin = 0) {
  if (
    isNotNull(variable) &&
    isString(variable) &&
    variable.length > countMin
  )
    return true;
  else return false;
}
export function var_defined(variable) {
  if (!isUndefined(variable)) return true;
  else return false;
}
export function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}
export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}
export function obj_isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return JSON.stringify(obj) === JSON.stringify({});
}
export function addToObject(obj, key, value, index) {
  // Create a temp object and index variable
  var temp = {};
  var i = 0;

  // Loop through the original object
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      // If the indexes match, add the new item
      if (i === index && key && value) {
        temp[key] = value;
      }

      // Add the current item in the loop to the temp obj
      temp[prop] = obj[prop];

      // Increase the count
      i++;
    }
  }

  // If no index, add to the end
  if (!index && key && value) {
    temp[key] = value;
  }

  return temp;
}
export function areObjectsEqual(x, y) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!areObjectsEqual(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    // allows x[ p ] to be set to undefined
  }
  return true;
}
export function getStringValue(value) {
  if (isNotNull(value)) return value;
  else return "";
}
export function hasOnlyOne(items) {
  if (isArrayCount(items) && items.length === 1) return true;
  else return false;
}
export function removeSpecialChars(string) {
  var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  return string.replace(invalid, "");
}
export function removeString(string) {
  var invalid = /[a-zA-Z]+/g;
  string = string.replace(invalid, "");
  var invalid = /[°$"§%()\[\]{}=\\?´`'#<>|,;:+_-]+/g;
  return string.replace(invalid, "");
}
export function seachString(string, search) {
  if (
    removeSpecialChars(string.toLowerCase().trim()).search(
      removeSpecialChars(search.toLowerCase().trim())
    ) >= 0
  )
    return true;
  else return false;
}
export function numeric(num) {
  if (isNotNull(num)) {
    num = num.toString().replace(".", ",");
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return num;
}

export function isCUITValid(value) {
  let document = null;

  if (isNotNull(value)) document = trim(value);

  if (isNull(document) || document.length !== 11) return false;

  var acumulado = 0;
  var digitos = document.split("");
  var digito = digitos.pop();

  for (var i = 0; i < digitos.length; i++) {
    acumulado += digitos[9 - i] * (2 + (i % 6));
  }

  var verif = 11 - (acumulado % 11);
  if (verif === 11) {
    verif = 0;
  } else if (verif === 10) {
    verif = 9;
  }

  return digito === verif;
}
export function removeReactivity(val) {
  if (isNotEmpty(val)) return JSON.parse(JSON.stringify(val));
  return null;
}
export function convertStringToInteger(val) {
  if (isNotNull(val))
    if (isString(val)) {
      let _return = val.toString().replace(/[.]/g, "");
      return parseInt(removeString(_return));
    } else return parseInt(val);
  else return null;
}
export function convertStringToFloat(val, round = true) {
  if (isNotNull(val)) {
    if (isString(val)) {
      let _return = val.toString().replace(/[.]/g, "").replace(",", ".");
      if (!round) {
        return parseFloat(removeString(_return));
      }
      return Math.round(parseFloat(removeString(_return)) * 100) / 100;
    } else return val;
  } else return null;
}
export function setFormatFloatValue(val, digit = 2) {
  if (isNotNull(val)) {
    let _return = (val / 1).toFixed(digit).replace(".", ",");
    return _return.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    // return val.toFixed(2)
    //           .replace('.', ',') // replace decimal point character with ,
    //           .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  } else return 0;
}
export function convertModelToFormData(
  data = {},
  formulario = null,
  namespace = ""
) {
  let files = {};
  let model = {};
  for (let propertyName in data) {
    if (
      data.hasOwnProperty(propertyName) &&
      data[propertyName] instanceof File
    ) {
      files[propertyName] = data[propertyName];
    } else {
      model[propertyName] = data[propertyName];
    }
  }

  model = JSON.parse(JSON.stringify(model));
  let formData = formulario || new FormData();

  for (let propertyName in model) {
    if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date)
      formData.append(formKey, model[propertyName].toISOString());
    else if (model[propertyName] instanceof File) {
      formData.append(formKey, model[propertyName]);
    } else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        if (typeof element === "object")
          convertModelToFormData(element, formData, tempFormKey);
        else formData.append(tempFormKey, element.toString());
      });
    } else if (
      typeof model[propertyName] === "object" &&
      !(model[propertyName] instanceof File)
    )
      convertModelToFormData(model[propertyName], formData, formKey);
    else {
      formData.append(formKey, model[propertyName].toString());
    }
  }

  for (let propertyName in files) {
    if (files.hasOwnProperty(propertyName)) {
      formData.append(propertyName, files[propertyName]);
    }
  }
  return formData;
}

export function getObjectFirstKey(obj) {
  if (isNotEmpty(obj)) {
    let kyz = Object.keys(obj);
    if (kyz.length === 1) return kyz[0];
  }
  return null;
}

export function isObjectKeyCount(obj, countQuantiy = 1) {
  if (isNotEmpty(obj)) {
    let kyz = Object.keys(obj);
    if (kyz.length >= countQuantiy) return true;
  }
  return false;
}
export function formatFloatNumber(value, round = false) {
  if (!round) return new Intl.NumberFormat("es-ES").format(value);
  else
    return new Intl.NumberFormat("es-ES").format(Math.round(value * 100) / 100);
}

export function utf8_decode(strData) {
  // eslint-disable-line camelcase
  //  discuss at: https://locutus.io/php/utf8_decode/
  // original by: Webtoolkit.info (https://www.webtoolkit.info/)
  //    input by: Aman Gupta
  //    input by: Brett Zamir (https://brett-zamir.me)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // improved by: Norman "zEh" Fuchs
  // bugfixed by: hitwork
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: kirilloid
  // bugfixed by: w35l3y (https://www.wesley.eti.br)
  //   example 1: utf8_decode('Kevin van Zonneveld')
  //   returns 1: 'Kevin van Zonneveld'

  var tmpArr = [];
  var i = 0;
  var c1 = 0;
  var seqlen = 0;

  strData += "";

  while (i < strData.length) {
    c1 = strData.charCodeAt(i) & 0xff;
    seqlen = 0;

    // https://en.wikipedia.org/wiki/UTF-8#Codepage_layout
    if (c1 <= 0xbf) {
      c1 = c1 & 0x7f;
      seqlen = 1;
    } else if (c1 <= 0xdf) {
      c1 = c1 & 0x1f;
      seqlen = 2;
    } else if (c1 <= 0xef) {
      c1 = c1 & 0x0f;
      seqlen = 3;
    } else {
      c1 = c1 & 0x07;
      seqlen = 4;
    }

    for (var ai = 1; ai < seqlen; ++ai) {
      c1 = (c1 << 0x06) | (strData.charCodeAt(ai + i) & 0x3f);
    }

    if (seqlen === 4) {
      c1 -= 0x10000;
      tmpArr.push(String.fromCharCode(0xd800 | ((c1 >> 10) & 0x3ff)));
      tmpArr.push(String.fromCharCode(0xdc00 | (c1 & 0x3ff)));
    } else {
      tmpArr.push(String.fromCharCode(c1));
    }

    i += seqlen;
  }

  return tmpArr.join("");
}

export function utf8_encode(argString) {
  // eslint-disable-line camelcase
  //  discuss at: https://locutus.io/php/utf8_encode/
  // original by: Webtoolkit.info (https://www.webtoolkit.info/)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // improved by: sowberry
  // improved by: Jack
  // improved by: Yves Sucaet
  // improved by: kirilloid
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // bugfixed by: Ulrich
  // bugfixed by: Rafał Kukawski (https://blog.kukawski.pl)
  // bugfixed by: kirilloid
  //   example 1: utf8_encode('Kevin van Zonneveld')
  //   returns 1: 'Kevin van Zonneveld'

  if (argString === null || typeof argString === "undefined") {
    return "";
  }

  // .replace(/\r\n/g, "\n").replace(/\r/g, "\n");
  var string = argString + "";
  var utftext = "";
  var start;
  var end;
  var stringl = 0;

  start = end = 0;
  stringl = string.length;
  for (var n = 0; n < stringl; n++) {
    var c1 = string.charCodeAt(n);
    var enc = null;

    if (c1 < 128) {
      end++;
    } else if (c1 > 127 && c1 < 2048) {
      enc = String.fromCharCode((c1 >> 6) | 192, (c1 & 63) | 128);
    } else if ((c1 & 0xf800) !== 0xd800) {
      enc = String.fromCharCode(
        (c1 >> 12) | 224,
        ((c1 >> 6) & 63) | 128,
        (c1 & 63) | 128
      );
    } else {
      // surrogate pairs
      if ((c1 & 0xfc00) !== 0xd800) {
        throw new RangeError("Unmatched trail surrogate at " + n);
      }
      var c2 = string.charCodeAt(++n);
      if ((c2 & 0xfc00) !== 0xdc00) {
        throw new RangeError("Unmatched lead surrogate at " + (n - 1));
      }
      c1 = ((c1 & 0x3ff) << 10) + (c2 & 0x3ff) + 0x10000;
      enc = String.fromCharCode(
        (c1 >> 18) | 240,
        ((c1 >> 12) & 63) | 128,
        ((c1 >> 6) & 63) | 128,
        (c1 & 63) | 128
      );
    }
    if (enc !== null) {
      if (end > start) {
        utftext += string.slice(start, end);
      }
      utftext += enc;
      start = end = n + 1;
    }
  }

  if (end > start) {
    utftext += string.slice(start, stringl);
  }

  return utftext;
}
export function dateAddDays(date, days) {
  if (isNotNull(date)) {
    var new_date = new Date(date);
    new_date.setDate(new_date.getDate() + days);
    return new_date;
  }
  return null;
}
export function dateformatString(date, char = "/") {
  if (isNotNull(date)) {
    let aux = date + "";
    aux = aux.split("T");
    if (isArrayCount(aux, 1)) {
      let date_array = aux[0].split("-");
      if (isArrayCount(date_array, 2)) {
        let year = date_array[0];
        let month = date_array[1];
        let day = date_array[2];
        return day + char + month + char + year;
      }
    }
  }
  return null;
}
export function datetimeFormatString(date, char = "/") {
  if (isNotNull(date)) {
    let aux = date + "";
    aux = aux.split("T");
    if (isArrayCount(aux, 1)) {
      let date_array = aux[0].split("-");
      if (isArrayCount(date_array, 2)) {
        let year = date_array[0];
        let month = date_array[1];
        let day = date_array[2];
        let time = "";
        let time_aux = aux[1];
        let time_array = time_aux.split(".");
        if (isArrayCount(time_array)) time = " " + time_array[0];
        return day + char + month + char + year + time;
      }
    }
  }
  return null;
}
export function dateformatPHP(date) {
  if (isNotNull(date)) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return null;
}
export function showDateFormat(date) {
  if (isNotNull(date)) {
    let array = date.split(" (");
    if (isArrayCount(array, 2, true)) {
      let _return = "";
      let date_single = array[0].split(" ");
      _return =
        '<i class="el-icon-time"></i><span class="date-time">' +
        date_single[0] +
        '</span><span class="date-time-diff">';

      let since = array[1].split(")");
      if (isArrayCount(since, 1, true)) {
        _return += since[0] + "</span>";
        return _return;
      }
    }
  }
  if (isNotNull(date)) return date;
  else return "";
}
export function showDatetimeFormat(date) {
  if (isNotNull(date)) {
    let array = date.split(" (");
    if (isArrayCount(array, 2, true)) {
      let _return = "";
      _return =
        '<i class="el-icon-time"></i><span class="date-time">' +
        array[0] +
        '</span><span class="date-time-diff">';

      let since = array[1].split(")");
      if (isArrayCount(since, 1, true)) {
        _return += since[0] + "</span>";
        return _return;
      }
    }
  }
  if (isNotNull(date)) return date;
  else return "";
}
export function inArray(needle, haystack) {
  for (var i = 0; i < haystack.length; i++) {
    if (haystack[i] === needle) return true;
  }
  return false;
}
export function hasNumberChars(value) {
  if (isNotNull(value)) return /^\d+$/.test(value);

  return false;
}
export function hasSpecialChars(value) {
  let format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (isNotNull(value) && format.test(value)) return true;
  return false;
}
export function getGlobalTypeDetail(id, list) {
  if (isArrayCount(list)) {
    for (let index in list) {
      if (list[index].id === id) return list[index];
    }
  }
  return false;
}
export function getGlobalTypeDescription(id, list) {
  let value = getGlobalTypeDetail(id, list);
  if (isNotEmpty(value) && isNotNull(value.description))
    return value.description;

  return null;
}
export function nl2br(str, is_xhtml = false) {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  var breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  str = (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
  str = str.replace("<br><br>", "<br>");
  return str;
}
export function cutString(
  value,
  number = 100,
  initial = 0,
  add_string = "..."
) {
  if (isStringCount(value, number + add_string.length)) {
    return value.slice(initial, number) + add_string;
  }
  return value;
}
export function formatPrice(value) {
  if (isNotNull(value)) {
    let val = (value / 1).toFixed(2).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return null;
}
export function setObjectFilesToRequest(
  request,
  object_file,
  file_name = "files"
) {
  let _return = request;
  if (isArrayCount(object_file)) {
    for (let index in object_file) {
      if (isNotNull(object_file[index].raw))
        _return.append(file_name + "[" + index + "]", object_file[index].raw);
      else
        _return.append(file_name + "[" + index + "]", object_file[index].url);
    }
  } else {
    if (isNotEmpty(object_file)) {
      if (isNotNull(object_file.raw))
        _return.append(file_name, object_file.raw);
      else _return.append(file_name, object_file.url);
    }
  }
  return _return;
}
export function setObjectValuesToNull(object) {
  let _return = object;
  if (isNotEmpty(_return)) {
    for (let index in _return)
      _return[index] = setObjectValuesToNull(_return[index]);
  } else {
    _return = null;
  }
  return _return;
}



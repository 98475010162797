import { CONTENTS_PREFIX_PATH } from "constants/route.constant"

const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: `${CONTENTS_PREFIX_PATH}/remito`, //'/home',
    unAuthenticatedEntryPath: '/login',
    tourPath: '/',
    enableMock: false
}

export default appConfig
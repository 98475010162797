import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import { errorHandler, successHandler } from 'utils/functions/axios'
import store from '../store'
import { onLogoutSuccess } from '../store/auth/sessionSlice'

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(config => {

    const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
    const persistData = deepParseJson(rawPersistData)
    config.headers.Accept = 'application/json';   
    // config.headers['Access-Control-Allow-Origin'] = '*';
    // config.headers.origin = 'x-requested-with';
    // config.headers['Access-Control-Allow-Headers'] = 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin'; 
    // config.headers['Connection'] = 'keep-alive';
    // config.headers['Accept'] = '*/*';
    // config.headers['Accept-Encoding'] = 'gzip, deflate, br';
        
    const accessToken = persistData.auth.session.token
    if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
        // console.log('template', `${TOKEN_TYPE}${accessToken}`);
    }
    
    return config
}, error => {
    const { response } = error
    return Promise.reject(errorHandler(error, response))
})

BaseService.interceptors.response.use(
    response => {
        return Promise.resolve(successHandler(response))
    },
    error => {

        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onLogoutSuccess())
        }
        return Promise.reject(errorHandler(error, response))
    }
)

export default BaseService
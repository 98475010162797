import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiLogin, apiLogout, apiSignUp, apiGetUserInfo } from 'services/auth/AuthService'
import { onLoginSuccess, onLogoutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { isNotNull } from 'utils/functions/general'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const login = async (values) => {
        try {
			const resp = await apiLogin(values)
			// console.log('LOGIN resp', resp)
			if (resp.data) {
				// console.log('LOGIN response', resp)
				const token = resp.data.token
				dispatch(onLoginSuccess(token))
				if(resp.data.user) {
					let user = resp.data.user;
						user.authority = ['administrator'];
						user.name = 'Usuario';
					if(!isNotNull(user.userName)) user.userName = 'Anonimo';
					if(!isNotNull(user.name)) user.name = 'Anonimo';
					if(!isNotNull(user.avatar)) user.avatar = '';
					
					dispatch(setUser(user || { 
						avatar: '', 
						userName: 'Anonymous', 
						authority: ['USER'], 
						name: '',
						idPerfil: 0
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const handleLogout = ()  => {
		dispatch(onLogoutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const logout = async () => {
		await apiLogout()
		handleLogout()
	}

	const getUserInfo = async () => {
        try {
			const resp = await apiGetUserInfo()
			// console.log('LOGIN USER', resp)
			if(resp.data && resp.data.user) {
				dispatch(setUser(resp.data.user || { 
					avatar: null, 
					userName: null, 
					authority: ['USER'], 
					name: null,
					idPerfil: 0
				}))
			}
			return {
				status: 'success',
				message: ''
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }
    
	const signUp = async (values) => {
        try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				const { token } = resp.data
				dispatch(onLoginSuccess(token))
				if(resp.data.user) {
					dispatch(setUser(resp.data.user || { 
						avatar: '', 
						userName: 'Anonymous', 
						authority: ['USER'], 
						name: '',
						idPerfil: 0
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    return {
        authenticated: token && signedIn,
        login,
		signUp,
		getUserInfo,
        logout
    }
}

export default useAuth
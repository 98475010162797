import { isNull, isObject, isString, isUndefined, trim } from "lodash";
import { isNotEmpty, isNotNull } from "./general";

export const getMessage = (response = {}) => {
  let _return = null;
  if (isNotEmpty(response) && isNotNull(response.data)) {
    if (
      isString(response.data) &&
      isNotNull(response.data) &&
      trim(response.data) !== ""
    ) {
      _return = response.data;
    } else {
      if (
        isNotNull(response.data.message) &&
        isString(response.data.message) &&
        trim(response.data.message) !== ""
      ) {
        _return = response.data.message;
      } else {
        if (
          isNotNull(response.data.error_message) &&
          isString(response.data.error_message) &&
          trim(response.data.error_message) !== ""
        ) {
          _return = response.data.error_message;
        } else {
          if (!isUndefined(response.data.error)) {
            if (
              isNotNull(response.data.error) &&
              isString(response.data.error) &&
              trim(response.data.error) !== ""
            ) {
              _return = response.data.error;
            } else {
              if (
                isNotNull(response.data.error.message) &&
                isString(response.data.error.message) &&
                trim(response.data.error.message) !== ""
              ) {
                _return = response.data.error.message;
              }
            }
          }
        }
      }
    }
  } else {
    if (isNotEmpty(response)) {
      if (
        isNotNull(response.message) &&
        isString(response.message) &&
        trim(response.message) !== ""
      ) {
        _return = response.message;
      } else {
        if (
          isNotNull(response.error) &&
          isString(response.error) &&
          trim(response.error) !== ""
        ) {
          _return = response.error;
        } else {
          if (
            isNotNull(response.error_message) &&
            isString(response.error_message) &&
            trim(response.error_message) !== ""
          ) {
            _return = response.error_message;
          } else {
            if (
              isNotEmpty(response.error) &&
              isNotNull(response.error.message) &&
              isString(response.error.message) &&
              trim(response.error.message) !== ""
            ) {
              _return = response.data.error.message;
            }
          }
        }
      }
    }
  }
  //if(isNotNull(_return)) console.log('muestra mensaje', _return);
  return _return;
};
export const hideMessageDisabled = (config = {}) => {
  if (isString(config) && config === "hideMessage") return true;
  if (isString(config) && !isObject(config)) config = JSON.parse(config);
  if (isNull(config) || isString(config)) return false;
  return isObject(config) &&
    (config.hasOwnProperty("hideMessage") || config.hideMessage)
    ? true
    : false;
};

export const returnResponse = (response = {}) => {
  if (
    isNotNull(response.data) &&
    isNotNull(response.data.data) &&
    isNotNull(response.data.success) &&
    isUndefined(response.data.meta)
  )
    return response.data;
  else return response;
};

export const errorHandler = (error, response) => {
  let urlCurrent = window.location.href;
  // console.log('current', urlCurrent);
  // console.log('current on login? ', urlCurrent.includes('/login'));
  if (
    isNotEmpty(error.response) &&
    isNotNull(error.response.status) &&
    parseInt(error.response.status) === 401 &&
    !urlCurrent.includes("/login")
  )
    window.location.href = "/login";
  if (!isUndefined(error.config))
    if (hideMessageDisabled(error.config)) return error;

  if (!isUndefined(error.config) && !isUndefined(error.config.data))
    if (hideMessageDisabled(error.config.data)) return error;

  if (
    !isUndefined(error.message) &&
    (isNotEmpty(error.message) ||
      (isString(error.message) && error.message === "hideMessage"))
  )
    if (hideMessageDisabled(error.message)) return error;

  if (!isUndefined(response)) if (hideMessageDisabled(response)) return error;

  if (!isUndefined(response.data))
    if (hideMessageDisabled(response.data)) return error;

  if (
    !isUndefined(response.message) &&
    (isNotEmpty(response.message) ||
      (isString(response.message) && response.message === "hideMessage"))
  ) {
    if (hideMessageDisabled(response.message)) return error;
  }

  let message = null;
  if (isNotNull(response)) message = getMessage(response);
  if (!isNotNull(message) && isNotNull(response.data))
    message = getMessage(response.data);

  if (!isNotNull(message)) message = getMessage(error);
  if (!isNotNull(message) && isNotNull(error.response))
    message = getMessage(error.response);
  if (!isNotNull(message) && isNotNull(error.response.data))
    message = getMessage(error.response.data);

  let statusValue = null;
  if (!isUndefined(error.status) && isString(error.status))
    statusValue = error.status;

  // store.dispatch("addToastMessage", {
  //   text: isNotNull(message)
  //     ? message
  //     : !isNull(status_value)
  //     ? "Respuesta de error: " + status_value
  //     : "Ha Ocurrido un inconveniente",
  //   type: "danger",
  // });

  return error;
};

export const successHandler = (response) => {
  if (!isUndefined(response.config)) {
    if (hideMessageDisabled(response.config)) return returnResponse(response);
  }
  if (!isUndefined(response.config) && !isUndefined(response.config.data)) {
    if (hideMessageDisabled(response.config.data))
      return returnResponse(response);
  }
  if (!isUndefined(response.message)) {
    if (hideMessageDisabled(response.message)) return returnResponse(response);
  }

  let message = getMessage(response);
  // if (isNotNull(message))
  //   store.dispatch("addToastMessage", {
  //     text: message,
  //     type: "success",
  //   });
  // if(isNotNull(response.headers.get("App-Version-Available")))
  //   console.log('HEADER RESPONSE', response.headers.get("App-Version-Available"));
  // else

  return returnResponse(response);
};

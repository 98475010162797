import { useMemo } from 'react'
import { isUndefined, isEmpty } from 'lodash'
import { isArrayCount } from 'utils/functions/general'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {

	if(!isArrayCount(userAuthority)) userAuthority = []
	
	if(!isArrayCount(authority)) authority = []
		
    const roleMatched = useMemo(() => {
		return authority.some(role => userAuthority.includes(role))
	}, [authority, userAuthority])
    
    if (isEmpty(authority) || isEmpty(userAuthority) || isUndefined(authority)) {
		return !emptyCheck
	}

    return roleMatched
}

export default useAuthority
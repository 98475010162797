import { isNull } from "lodash";
//CONFIGURATION API TOKEN 
export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'

//CONFIGURATION API 
// SERVIDOR PRUEBA 1
// export const API_URL = 'http://189.203.201.168:3502'; 
// export const API_URL_PATH = API_URL + '/SinergieApiPrueba/api'; //PRUEBA

// SERVIDOR DE PRUEBA EZE
// export const API_URL = 'http://elombardi1986.ddns.net'; 
// export const API_URL_PATH = API_URL + '/SinergieMxApi/api'; 

// SERVIDOR DE PROD MX
export const API_URL = 'https://sistemas.tpvsistemas.com'; 
export const API_URL_PATH = API_URL + '/SinergieApi/api'; 
// https://sistemas.tpvsistemas.com

export const API_URL_INVENTARIO = 'https://api.myjson.online/v1/records/fa126bad-4e42-469d-961d-c41bcec9c4fd';

export const API_URL_PATH_VERSION = API_URL_PATH + '';
// https://elombardi1986.ddns.net/SinergieMxApi/api/
export const API_AUTH_GRAND_TYPE = null;
export const API_AUTH_CLIENT_ID = null;
export const API_AUTH_CLIENT_SECRET = null;

let api_configuration = {
    Accept: 'application/json',
    // mode: 'no-cors'
};
export const API_SERVICE_CONFIG = api_configuration;

let api_auth_configuration = api_configuration;
if(!isNull(API_AUTH_GRAND_TYPE))
    api_auth_configuration.grant_type = API_AUTH_GRAND_TYPE;
if(!isNull(API_AUTH_CLIENT_ID))
    api_auth_configuration.client_id = API_AUTH_CLIENT_ID;
if(!isNull(API_AUTH_CLIENT_SECRET))
    api_auth_configuration.client_secret = API_AUTH_CLIENT_SECRET; 

export const API_AUTH_LOGIN_CONFIG = api_auth_configuration;